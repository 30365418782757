<template>
  <div class="info-fault-wrapper">
    <ul>
      <li>
        <div class="info-wrapper">
          <span>保养内容：</span>
          <span>{{detailInfo&&detailInfo.maintenanceContent}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养照片：</span>
          <template v-if="detailInfo&&detailInfo.maintainPictureUrl">
            <van-image v-for="items in detailInfo.maintainPictureUrl.split(',')" :key="items" width="2.5rem" height="2.5rem" :src="imgUrl + items" />
          </template>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养时间：</span>
          <span>{{detailInfo&&detailInfo.finishStartTime+''+detailInfo&&detailInfo.finishEndTime}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>保养人员：</span>
          <span>{{detailInfo&&detailInfo.finishUserNames}}</span>
        </div>
      </li>
      <li>
        <div class="info-wrapper">
          <span>审核人员：</span>
          <span>{{detailInfo&&detailInfo.approvalUserNames}}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import { Field, Picker, Popup, Image as VanImage } from 'vant'
Vue.use(Picker)
Vue.use(Field)
Vue.use(Popup)
Vue.use(VanImage)
import { imgUrl, QiNiuUrl } from '@/config/env'

export default {
  name: 'InfoFault',
  components: {
  },
  data() {
    return {
      imgUrl,

      query: null,
      stopInterval: '',
    }
  },
  props: {
    detailInfo: {
      type: Object,
      default() {
        return null
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.query = this.$route.query
    })
  },
  watch: {
    detailInfo: {
      handler(val) {
        if (val) {
          this.stopInterval = val.stopInterval
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() { },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
@import '../../../style/mixin';
.info-fault-wrapper {
  ul {
    li {
      padding: 0.45rem 0;
      border: 1px solid #ebedf0;
      .info-wrapper {
        padding: 0 1.5rem;
        display: flex;
        flex-direction: row;
        .van-image {
          width: 0.5rem;
          height: 0.5rem;
          margin: 0 0.5rem 0 0;
        }
      }
      span {
        color: #333;
        font-size: 0.6rem;
        font-weight: bold;
      }
      span:nth-child(1) {
        padding-right: 0.2rem;
      }
      span:nth-child(2) {
        width: 0;
        flex: 1;
        word-wrap: break-word;
        word-break: normal;
      }
    }
  }
}
</style>
